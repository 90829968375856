/**
 * @fileOverview Types module.
 * Gets all Types from the database
 * @module frontend/models/types
 * @author Joris Karl Dzaack <dzaack@laborb.de>
 * @copyright labor b designbüro, 2015
 */
'use strict';

import fast from 'fast.js';

import DataHandler from './DataHandler.js';
import Type from './Type.js';

/**
 * Types class
 * @classdesc Gets all types in the database by an API call
 * @extends DataHandler
 * @requires module:frontend/models/type
 * @see module:frontend/models/dataHandler
 * @class Types
 * @public
 */
export default class Types extends DataHandler {
  /**
   * Constructor for the Type class
   * @constructs Types
   */
  constructor() {
    super();
    this.path = '/api/types';
  }

  /**
   * Returns specific type by an id
   * @param  {string} id id of the type
   * @return {object}    the found type object
   */
  getTypeForId(id) {
    return fast.filter(this.data, (type) => {
        return type.id === id;
    })[0];
  }

  /**
   * Loads and returns an array of type objects
   * @see frontend/models/type
   * @return {promise} Promise after which the data is present in the instance
   * @override
   */
  load() {
    return super.load().then(() => {
        this.data = this.data.map((type) => {
            return new Type(type);
        });
    });
  }
}
