'use strict';

import domready from 'domready';
import React from 'react/addons';
import Router from 'react-router';

import routes from './routes/routes.jsx';

import addClass from 'amp-add-class';

//import gsap from 'gsap';


class ZUFI {
  constructor() {

  }

  init() {
    domready(() => {
      if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
        addClass(document.body, 'touch');
      }

      React.initializeTouchEvents(true);

      Router.run(routes, Router.HistoryLocation, function (Handler) {
        React.render(<Handler/>, document.getElementsByClassName('main')[0]);
      });
    });
  }
}

window.ZUFI = new ZUFI();
