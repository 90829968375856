/**
 * @fileOverview getStyle module.
 * @module utils/getStyle
 * @author Joris Karl Dzaack <dzaack@laborb.de>
 * @copyright labor b designbüro, 2015
 */
'use strict';

/**
 * Returns the computed style of a element for a specific declaration
 * @param  {HTMLElement} element the element of interest
 * @param  {String} the declaration to search for
 * @return {String} the computed value
 */
export default function getStyle (element, declaration) {
  return (function (declaration) {
    return window.getComputedStyle(this).getPropertyValue(declaration);
  }).call(element, declaration);
}
