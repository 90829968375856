/**
 * @file
 * Holds the ResultBillboard component.
 * @fileOverview
 * Holds the ResultBillboard component.
 * @module react/ResultBillboard
 * @author Joris Karl Dzaack <dzaack@laborb.de>
 * @copyright labor b designbüro, 2015
 */
'use strict';

import url from 'url';

import BerufsFeld from './BerufsFeld.jsx'

import React from 'react/addons';
import Meta from '../frontend/models/Meta.js';

import fast from 'fast.js';
import marked from 'marked';

import fitText from '../frontend/utils/fitText.js';
import tokenReplacer from '../frontend/utils/tokenReplacer.js';

import siteConfig from '../config/site.js';

/**
 * Function for open a link in a popup.
 * @param  {String} url         URL to open in popup.
 * @param  {String} [windowName = '_blank']  Window name.
 * @param  {String} [windowSizeX = '600']   Window size.
 * @param  {String} [windowSizeY = '460'] Window size.
 */
let popup = (url, windowName = '_blank', windowSizeX = '600', windowSizeY = '460') => {
  let windowSize = 'width=' + windowSizeX + ',height=' + windowSizeY;

  window.open(url, windowName, windowSize);
}

/**
 * Result component
 * @classdesc The component for the "Questions".
 * @requires module:react/BerufsFeld
 * @requires module:frontend/models/Meta
 * @requires module:frontend/utils/fitText
 * @requires module:frontend/utils/tokenReplacer
 * @requires module:config/site
 * @class Result
 * @public
 */
class ResultBillboard extends React.Component {
  /**
   * Constructs the ResultBillboard
   * @constructs ResultBillboard
   */
  constructor(props) {
      super(props);

      this.resizeTimeout;

      this.state = {
          // 'type': {},
          'name': '',
          'description': '',
          'image': ''
      };
  }

  /**
   * Private event handler.
   * Calls _handleScreenChange on resize and orientation change.
   * @param  {Event} event Event
   */
  _handleEvent(event) {
    switch(event.type) {
      case 'resize':
      case 'orientationchange':
        this._handleScreenChange();
      break;
    }
  }

  /**
   * Handles the Facebook share dialog.
   * Opens the facebook share dialog in a new window.
   * @private
   */
  _handleFacebook() {
    FB.ui({
      method: 'share',
      href: window.location.href,
      }, function(response){
        console.log(response);
    });
  }

  /**
   * Handles the Twitter share dialog.
   * Opens the Twitter share dialog in a new window.
   * @private
   */
  _handleTwitter() {
    let shareUrl = url.parse('https://twitter.com/intent/tweet', true);
    shareUrl.query.url = window.location.href;
    shareUrl.query.text = `Mein Ergebnis beim #Zukunftsfinder Kompass: ${this.state.name}. Wer bist Du?`;
    shareUrl.query.hashtags = '';
    popup(url.format(shareUrl));
  }

  /**
   * Handles Twitter and Facebook sharing.
   * @private
   * @param  {String} type Type of share.
   */
  _handleShare(type) {
    switch(type) {
      case 'facebook':
        this._handleFacebook();
      break;

      case 'twitter':
        this._handleTwitter();
      break;

      default:
    }
  }

  /**
   * Mathod called when the window is resized or the orientation changes.
   * Forces the result name to fit in the container.
   * @private
   */
  _handleScreenChange() {
    if(!this.resizeTimeout) {
      this.resizeTimeout = setTimeout(() => {
        this.resizeTimeout = null;
        this._adjustResultNameSize();
      }, 66);
    }
  }

  /**
   * Adjusts the size of the result name to fit in the container.
   * @private
   */
  _adjustResultNameSize() {
    let resultNameContainer = React.findDOMNode(this.refs.resultName);
    fitText(resultNameContainer, 1);
  }

  /**
   * Event handler. 
   * Passes the event to the private event handler method.
   * @param  {Event} event Event
   */
  handleEvent(event) {
    this._handleEvent(event);
  }

  /**
   * Fires after the component has been mounted.
   * Adjusts the size of the result name in the container.
   */
  componentDidMount() {
    this._adjustResultNameSize();
  }

  /**
   * Fires after the component has been updated.
   * Adjusts the size of the result name in the container.
   */
  componentDidUpdate() {
    this._adjustResultNameSize();
  }

  /**
   * Fires before the component has been mounted.
   * Gets meta data from the data base and sets the state according to its values.
   * Adds event listener to the window. 
   */
  componentWillMount() {
      let persoenlichkeitsTypMeta = new Meta(this.props.persoenlichkeitId);
      
      persoenlichkeitsTypMeta.load().then(() => {
          this.setState({
              'name': persoenlichkeitsTypMeta.data.alter[this.props.gender].name,
              'description': persoenlichkeitsTypMeta.data.alter[this.props.gender].description,
              'image': persoenlichkeitsTypMeta.data.alter[this.props.gender].image
          });
      });

      window.addEventListener('resize', this, false);
      window.addEventListener('orientationchange', this, false);
  }

  /**
   * Fires before the component unmounts.
   * Removes the event listeners from the window.
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this);
    window.removeEventListener('orientationchange', this);
  }

  /**
   * Default render method. 
   */
  render() {
    let graphicsPath = siteConfig.paths.graphics;
    let typesPath = siteConfig.paths.types;
    let imageURL = typesPath+this.state.image;

    let rawDescription = tokenReplacer(marked(this.state.description, {sanitize: true}), siteConfig.replacements);

      return (
          <div className="results__result__billboard">
              <div className="billboard-board">
                  <div className="alter">
                    <div className="alter__person">
                      <img src={imageURL} className="alter__person__image" />
                      <div className="alter__person__title">
                        <img src={graphicsPath+'result-label.svg'} className="alter__person__title__banderole" />
                        <strong ref="resultName" className="alter__person__title__text">{this.state.name}</strong>
                      </div>
                      <div className="alter__person__star alter__person__star--top">
                        <img src={graphicsPath+'result-star.svg'} className="alter__person__star__image" />
                      </div>
                      <div className="alter__person__star alter__person__star--bottom">
                        <img src={graphicsPath+'result-star.svg'} className="alter__person__star__image" />
                      </div>
                    </div>
                  </div>
                  <div className="billboard-board__description">
                    <div className="billboard-board__description__content">
                      <h4 className="billboard-board__description__content__headline">Dein Ergebnis</h4>
                      <div className="billboard-board__description__content__text" dangerouslySetInnerHTML={{__html: rawDescription}} />
                      <BerufsFeld typeId={this.props.berufId} />
                      <ul className="share">
                        <li className="share__item"><strong className="share__item__button" data-type="twitter" onClick={fast.bind(this._handleShare,this, 'twitter')}>tweet</strong></li>
                        <li className="share__item"><strong className="share__item__button" data-type="facebook" onClick={fast.bind(this._handleShare,this, 'facebook')}>teilen</strong></li>
                      </ul>
                    </div>
                  </div>
              </div>
              <hr />
          </div>
      );
  }
}

ResultBillboard.propTypes = {
    persoenlichkeitId: React.PropTypes.string,
    berufId: React.PropTypes.string,
    gender: React.PropTypes.string
};

ResultBillboard.defaultProps = {
  gender: "male"
};

export default ResultBillboard;
