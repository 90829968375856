/**
 * @fileOverview Intermediate class.
 * @module frontend/models/intermediate
 * @author Joris Karl Dzaack <dzaack@laborb.de>
 * @copyright labor b designbüro, 2015
 */
import fast from 'fast.js';

/**
 * Intermediate class
 * @class Intermediate
 * @public
 */
export default class Intermediate {
    /**
     * The constructor for the Intermediate class
     * @constructs Intermediate
     */
    constructor() {
        this._choices = [];
    }

    _getTopTypes(matrix, n) {
        matrix = fast.clone(matrix);
        let values = [];

        for(let i = 1; i <= n; i++) {
            let top = this._getKeyOfHighestValue(matrix);
            delete matrix[top];
            values.push(top);
        }

        return values;
    }

    /**
     * Returns the key which has set the highest value
     * @private
     * @param  {object} objecttosearch Object to search through
     * @return {string}                The key of the highes value
     */
    _getKeyOfHighestValue(objecttosearch) {
        var highestValue, key;
        highestValue = -1;
        key = null;
        for (var index in objecttosearch) {
          if (objecttosearch.hasOwnProperty(index) && parseInt(objecttosearch[index]) > highestValue) {
            highestValue = objecttosearch[index];
            key = index;
          }
        }
        return key;
    }

    /**
     * Merges/Flattens the values in a matrix
     * @private
     * @param  {array} matrix array of two matrices
     * @return {object}        object of key value pairs of type and type value
     */
    _mergeValues(matrix) {
        let values = {};

        fast.forEach(matrix, (matrixValues) => {
            fast.forEach(matrixValues, (typeValue, typeKey) => {
                if(values.hasOwnProperty(typeKey)) {
                    values[typeKey] += typeValue;
                } else {
                    values[typeKey] = typeValue;
                }
            });
        });

        return values;
    }

    /**
     * Filter function for partial binding in choices getter
     * @private
     * @param  {number} matrix id of matrix
     * @param  {any} data   data passed by the filter function
     * @return {bool}        returns true if data.matrix is exactly matrix
     */
    _filterMatrix(matrix, data) {
        return data.matrix === matrix;
    }

    /**
     * Removes last question from the questions array
     * @deprecated 
     * @return {undefined}
     */
    removeLastQuestion() {
      this._choices.pop();
    }

    /**
     * Adds another choice to the choice array
     * @param  {object} choice the choice that will be added
     * @type   {object}
     */
    set choices(choice) {
      this._choices.push(choice);
    }

    /**
     * Returns the two highes choices of each matrix
     * @return {array} Array containing the two matrices with its top types
     */
    get choices() {
        let matrices = fast.map(new Array(2), (m,i) => {
            let filter = fast.partial(this._filterMatrix, i),
            matrix = fast.filter(this._choices, filter);

            matrix = this._mergeValues(fast.map(matrix, (choice) => {
                return choice.values;
            }));

            return this._getTopTypes(matrix, 2);
        }, this);

        return matrices;
    }
}
