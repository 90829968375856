/**
 * @fileOverview SmoothScroll module.
 * @module utils/smoothScroll
 * @author Chris Ferdinandi & Joris Karl Dzaack <dzaack@laborb.de>
 * @copyright labor b designbüro, 2015
 */
'use strict';

import {forEach} from 'fast.js';

//
// Variables
//

var smoothScroll = {}; // Object for public APIs
var supports = !!document.querySelector && !!window.addEventListener; // Feature test
var settings, eventTimeout, fixedHeader, headerHeight;

// Default settings
var defaults = {
  speed: 500,
  easing: 'easeInOutCubic',
  offset: 0,
  updateURL: true,
  callbackBefore: function () {},
  callbackAfter: function () {}
};


//
// Methods
//

/**
 * Merge defaults with user options
 * @private
 * @param {Object} defaults Default settings
 * @param {Object} options User options
 * @returns {Object} Merged values of defaults and options
 */
var extend = function ( defaults, options ) {
  var extended = {};
  forEach(defaults, function (value, prop) {
    extended[prop] = defaults[prop];
  });
  forEach(options, function (value, prop) {
    extended[prop] = options[prop];
  });
  return extended;
};

/**
 * Get the closest matching element up the DOM tree
 * @param {Element} elem Starting element
 * @param {String} selector Selector to match against (class, ID, or data attribute)
 * @return {Boolean|Element} Returns false if not match found
 */
var getClosest = function (elem, selector) {
  var firstChar = selector.charAt(0);
  for ( ; elem && elem !== document; elem = elem.parentNode ) {
    if ( firstChar === '.' ) {
      if ( elem.classList.contains( selector.substr(1) ) ) {
        return elem;
      }
    } else if ( firstChar === '#' ) {
      if ( elem.id === selector.substr(1) ) {
        return elem;
      }
    } else if ( firstChar === '[' ) {
      if ( elem.hasAttribute( selector.substr(1, selector.length - 2) ) ) {
        return elem;
      }
    }
  }
  return false;
};

/**
 * Get the height of an element
 * @private
 * @param  {Node} elem The element
 * @return {Number}     The element's height
 */
var getHeight = function (elem) {
  return Math.max( elem.scrollHeight, elem.offsetHeight, elem.clientHeight );
};

/**
 * Escape special characters for use with querySelector
 * @private
 * @param {String} id The anchor ID to escape
 * @author Mathias Bynens
 * @link https://github.com/mathiasbynens/CSS.escape
 */
var escapeCharacters = function ( id ) {
  var string = String(id);
  var length = string.length;
  var index = -1;
  var codeUnit;
  var result = '';
  var firstCodeUnit = string.charCodeAt(0);
  while (++index < length) {
    codeUnit = string.charCodeAt(index);
    // Note: there’s no need to special-case astral symbols, surrogate
    // pairs, or lone surrogates.

    // If the character is NULL (U+0000), then throw an
    // `InvalidCharacterError` exception and terminate these steps.
    if (codeUnit === 0x0000) {
      throw new InvalidCharacterError(
        'Invalid character: the input contains U+0000.'
      );
    }

    if (
      // If the character is in the range [\1-\1F] (U+0001 to U+001F) or is
      // U+007F, […]
      (codeUnit >= 0x0001 && codeUnit <= 0x001F) || codeUnit == 0x007F ||
      // If the character is the first character and is in the range [0-9]
      // (U+0030 to U+0039), […]
      (index === 0 && codeUnit >= 0x0030 && codeUnit <= 0x0039) ||
      // If the character is the second character and is in the range [0-9]
      // (U+0030 to U+0039) and the first character is a `-` (U+002D), […]
      (
        index === 1 &&
        codeUnit >= 0x0030 && codeUnit <= 0x0039 &&
        firstCodeUnit === 0x002D
      )
    ) {
      // http://dev.w3.org/csswg/cssom/#escape-a-character-as-code-point
      result += '\\' + codeUnit.toString(16) + ' ';
      continue;
    }

    // If the character is not handled by one of the above rules and is
    // greater than or equal to U+0080, is `-` (U+002D) or `_` (U+005F), or
    // is in one of the ranges [0-9] (U+0030 to U+0039), [A-Z] (U+0041 to
    // U+005A), or [a-z] (U+0061 to U+007A), […]
    if (
      codeUnit >= 0x0080 ||
      codeUnit === 0x002D ||
      codeUnit === 0x005F ||
      codeUnit >= 0x0030 && codeUnit <= 0x0039 ||
      codeUnit >= 0x0041 && codeUnit <= 0x005A ||
      codeUnit >= 0x0061 && codeUnit <= 0x007A
    ) {
      // the character itself
      result += string.charAt(index);
      continue;
    }

    // Otherwise, the escaped character.
    // http://dev.w3.org/csswg/cssom/#escape-a-character
    result += '\\' + string.charAt(index);

  }
  return result;
};

/**
 * Calculate the easing pattern
 * @private
 * @link https://gist.github.com/gre/1650294
 * @param {String} type Easing pattern
 * @param {Number} time Time animation should take to complete
 * @returns {Number}
 */
var easingPattern = function ( type, time ) {
  var pattern;
  if ( type === 'easeInQuad' ) pattern = time * time; // accelerating from zero velocity
  if ( type === 'easeOutQuad' ) pattern = time * (2 - time); // decelerating to zero velocity
  if ( type === 'easeInOutQuad' ) pattern = time < 0.5 ? 2 * time * time : -1 + (4 - 2 * time) * time; // acceleration until halfway, then deceleration
  if ( type === 'easeInCubic' ) pattern = time * time * time; // accelerating from zero velocity
  if ( type === 'easeOutCubic' ) pattern = (--time) * time * time + 1; // decelerating to zero velocity
  if ( type === 'easeInOutCubic' ) pattern = time < 0.5 ? 4 * time * time * time : (time - 1) * (2 * time - 2) * (2 * time - 2) + 1; // acceleration until halfway, then deceleration
  if ( type === 'easeInQuart' ) pattern = time * time * time * time; // accelerating from zero velocity
  if ( type === 'easeOutQuart' ) pattern = 1 - (--time) * time * time * time; // decelerating to zero velocity
  if ( type === 'easeInOutQuart' ) pattern = time < 0.5 ? 8 * time * time * time * time : 1 - 8 * (--time) * time * time * time; // acceleration until halfway, then deceleration
  if ( type === 'easeInQuint' ) pattern = time * time * time * time * time; // accelerating from zero velocity
  if ( type === 'easeOutQuint' ) pattern = 1 + (--time) * time * time * time * time; // decelerating to zero velocity
  if ( type === 'easeInOutQuint' ) pattern = time < 0.5 ? 16 * time * time * time * time * time : 1 + 16 * (--time) * time * time * time * time; // acceleration until halfway, then deceleration
  if ( type === 'easeInOutSine' ) pattern = (Math.sin(time * Math.PI - 0.5 * Math.PI) + 1) * 0.5;
  return pattern || time; // no easing, no acceleration
};

/**
 * Calculate how far to scroll
 * @private
 * @param {Element} anchor The anchor element to scroll to
 * @param {Number} headerHeight Height of a fixed header, if any
 * @param {Number} offset Number of pixels by which to offset scroll
 * @returns {Number}
 */
var getEndLocation = function ( anchor, headerHeight, offset ) {
  var location = 0;
  if (anchor.offsetParent) {
    do {
      location += anchor.offsetTop;
      anchor = anchor.offsetParent;
    } while (anchor);
  }
  location = location - headerHeight - offset;
  return location >= 0 ? location : 0;
};

/**
 * Determine the document's height
 * @private
 * @returns {Number}
 */
var getDocumentHeight = function () {
  return Math.max(
    document.body.scrollHeight, document.documentElement.scrollHeight,
    document.body.offsetHeight, document.documentElement.offsetHeight,
    document.body.clientHeight, document.documentElement.clientHeight
  );
};

/**
 * Convert data-options attribute into an object of key/value pairs
 * @private
 * @param {String} options Link-specific options as a data attribute string
 * @returns {Object}
 */
var getDataOptions = function ( options ) {
  return !options || !(typeof JSON === 'object' && typeof JSON.parse === 'function') ? {} : JSON.parse( options );
};

/**
 * Update the URL
 * @private
 * @param {Element} anchor The element to scroll to
 * @param {Boolean} url Whether or not to update the URL history
 */
var updateUrl = function ( anchor, url ) {
  if ( history.pushState && (url || url === 'true') ) {
    history.pushState( null, null, [window.location.protocol, '//', window.location.host, window.location.pathname, window.location.search, anchor].join('') );
  }
};

var getHeaderHeight = function ( header ) {
  return header === null ? 0 : ( getHeight( header ) + header.offsetTop );
};

/**
 * Start/stop the scrolling animation
 * @public
 * @param {Element} toggle The element that toggled the scroll event
 * @param {Element} anchor The element to scroll to
 * @param {Object} options
 */
smoothScroll.animateScroll = function ( toggle, anchor, options ) {

  // Options and overrides
  var settings = extend( settings || defaults, options || {} );  // Merge user options with defaults
  var overrides = getDataOptions( toggle ? toggle.getAttribute('data-options') : null );
  settings = extend( settings, overrides );
  anchor = '#' + escapeCharacters(anchor.substr(1)); // Escape special characters and leading numbers

  // Selectors and variables
  var anchorElem = anchor === '#' ? document.documentElement : document.querySelector(anchor);
  var startLocation = window.pageYOffset; // Current location on the page
  if ( !fixedHeader ) { fixedHeader = document.querySelector('[data-scroll-header]'); }  // Get the fixed header if not already set
  if ( !headerHeight ) { headerHeight = getHeaderHeight( fixedHeader ); } // Get the height of a fixed header if one exists and not already set
  var endLocation = getEndLocation( anchorElem, headerHeight, parseInt(settings.offset, 10) ); // Scroll to location
  var animationInterval; // interval timer
  var distance = endLocation - startLocation; // distance to travel
  var documentHeight = getDocumentHeight();
  var timeLapsed = 0;
  var percentage, position;

  // Update URL
  updateUrl(anchor, settings.updateURL);

  /**
   * Stop the scroll animation when it reaches its target (or the bottom/top of page)
   * @private
   * @param {Number} position Current position on the page
   * @param {Number} endLocation Scroll to location
   * @param {Number} animationInterval How much to scroll on this loop
   */
  var stopAnimateScroll = function (position, endLocation, animationInterval) {
    var currentLocation = window.pageYOffset;
    if ( position == endLocation || currentLocation == endLocation || ( (window.innerHeight + currentLocation) >= documentHeight ) ) {
      clearInterval(animationInterval);
      anchorElem.focus();
      settings.callbackAfter( toggle, anchor ); // Run callbacks after animation complete
    }
  };

  /**
   * Loop scrolling animation
   * @private
   */
  var loopAnimateScroll = function () {
    timeLapsed += 16;
    percentage = ( timeLapsed / parseInt(settings.speed, 10) );
    percentage = ( percentage > 1 ) ? 1 : percentage;
    position = startLocation + ( distance * easingPattern(settings.easing, percentage) );
    window.scrollTo( 0, Math.floor(position) );
    stopAnimateScroll(position, endLocation, animationInterval);
  };

  /**
   * Set interval timer
   * @private
   */
  var startAnimateScroll = function () {
    settings.callbackBefore( toggle, anchor ); // Run callbacks before animating scroll
    animationInterval = setInterval(loopAnimateScroll, 16);
  };

  /**
   * Reset position to fix weird iOS bug
   * @link https://github.com/cferdinandi/smooth-scroll/issues/45
   */
  if ( window.pageYOffset === 0 ) {
    window.scrollTo( 0, 0 );
  }

  // Start scrolling animation
  startAnimateScroll();

};

/**
 * If smooth scroll element clicked, animate scroll
 * @private
 */
var eventHandler = function (event) {
  var toggle = getClosest(event.target, '[data-scroll]');
  if ( toggle && toggle.tagName.toLowerCase() === 'a' ) {
    event.preventDefault(); // Prevent default click event
    smoothScroll.animateScroll( toggle, toggle.hash, settings); // Animate scroll
  }
};

/**
 * On window scroll and resize, only run events at a rate of 15fps for better performance
 * @private
 * @param  {Function} eventTimeout Timeout function
 * @param  {Object} settings
 */
var eventThrottler = function (event) {
  if ( !eventTimeout ) {
    eventTimeout = setTimeout(function() {
      eventTimeout = null; // Reset timeout
      headerHeight = getHeaderHeight( fixedHeader ); // Get the height of a fixed header if one exists
    }, 66);
  }
};

/**
 * Destroy the current initialization.
 * @public
 */
smoothScroll.destroy = function () {

  // If plugin isn't already initialized, stop
  if ( !settings ) return;

  // Remove event listeners
  document.removeEventListener( 'click', eventHandler, false );
  window.removeEventListener( 'resize', eventThrottler, false );

  // Reset varaibles
  settings = null;
  eventTimeout = null;
  fixedHeader = null;
  headerHeight = null;
};

/**
 * Initialize Smooth Scroll
 * @public
 * @param {Object} options User settings
 */
smoothScroll.init = function ( options ) {

  // feature test
  if ( !supports ) return;

  // Destroy any existing initializations
  smoothScroll.destroy();

  // Selectors and variables
  settings = extend( defaults, options || {} ); // Merge user options with defaults
  fixedHeader = document.querySelector('[data-scroll-header]'); // Get the fixed header
  headerHeight = getHeaderHeight( fixedHeader );

  // When a toggle is clicked, run the click handler
  document.addEventListener('click', eventHandler, false );
  if ( fixedHeader ) { window.addEventListener( 'resize', eventThrottler, false ); }

};


//
// Public APIs
//

export default smoothScroll;
