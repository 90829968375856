/**
 * @fileOverview fitText module.
 * @module utils/getComputedProperty
 * @author Joris Karl Dzaack <dzaack@laborb.de>
 * @copyright labor b designbüro, 2015
 */
'use strict';

/**
 * Port of fitText.js
 * @param  {HTMLElement} element     The DOM Element to target
 * @param  {String} property         the property to return
 * @return {String}                  property value
 */
export default function getComputedProperty(element, property) {
  let result;

  try {
    result = window.getComputedStyle(element, null).getPropertyValue(property);
  } catch(e) {
    result = element.currentStyle[property];
  }

  return result ? result : -1;
}
