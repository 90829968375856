/**
 * @fileOverview Data Handler.
 * @module frontend/models/dataHandler
 * @author Joris Karl Dzaack <dzaack@laborb.de>
 * @copyright labor b designbüro, 2015
 */

'use strict';

import http from 'http';

/**
 * DataHandler class
 * @classdesc Gets arbitrary from an API call. Used only as a superclass for API calls.
 * @see module:frontend/models/dataHandler
 * @class DataHandler
 * @public
 */
export default class DataHandler {
    /**
     * Constructor of the DataHandler class
     * @constructs DataHandler
     */
    constructor() {
        this.data = {};
    }

    /**
     * Returns a promise; after its resolving, all data necessary is present in the instance
     * @memberOf DataHandler
     * @return {promise} Promise
     */
    load() {
        return new Promise((resolve, reject) => {
            http.get({
                path: this.path
            }, (response) => {
                let body = '';

                response.on('data', (data) => {
                    body += data;
                });

                response.on('end', () => {
                    this.data = JSON.parse(body);

                    resolve(this.data);
                });
            });
        });
    }

    /**
     * Allows the instances to be iterable
     * @memberOf DataHandler
     */
    * [Symbol.iterator]() {
        for (let entity of this.data) {
            yield entity;
        }
    }
}
