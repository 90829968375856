/**
 * @fileOverview Choice Model.
 * @module frontend/models/choice
 * @author Joris Karl Dzaack <dzaack@laborb.de>
 * @copyright labor b designbüro, 2015
 */

'use strict';

import fast from 'fast.js';

/**
 * Choice class
 * @extends DataHandler
 * @class Choice
 * @public
 */
export default class Choice {
    /**
     * Constructor for the Choice class
     * @constructs Choice
     * @param  {string} data object containing all the data necessary for the calculations
     */
    constructor(data) {
        this.id = data._id;
        this.matrix = data.matrix;
        this.phaseOne = data.phaseOne;
        this.text = data.text;
        this.features = this.featuresToObject(data.features);
        this.values = {};
    }

    /** Converts a array of features to a object
     * @method featuresToObject
     * @public
     * @memberof Choice
     * @param {array} - feature array.
     */
    featuresToObject(f) {
        let features = {};

        if (!f || f.length === 0) {
            return;
        }

        fast.forEach(f, function(feature) {
            this[feature] = true;
        }, features);

        return features;
    }

    /**
     * Calculates all choice values according to the global types
     * @method calcValues
     * @public
     * @memberof Choice
     * @param {object} types types-object
     */
    calcValues(types) {
        if (!this.features) {
            return;
        }

        fast.forEach(types, function(type) {
            if(type.matrix !== this.matrix) {
                return;
            }

            var sum = 0;

            for (var key in type.values) {
                if (this.features.hasOwnProperty(key) && this.features[key] === true) {
                    sum += (type.values[key]);
                }
            }

            this.values[type.id] = sum;

        }, this);
    }

    /**
     * Returns the key which has set the highest value
     * @method keyOfHighestValue
     * @private
     * @memberof Choice
     * @param  {object} objecttosearch Object to search through
     * @return {string}                The key of the highes value
     */
    keyOfHighestValue(objecttosearch) {
        var highestValue, key;
        highestValue = -1;
        key = null;
        for (var index in objecttosearch) {
            if (objecttosearch.hasOwnProperty(index) && parseInt(objecttosearch[index]) > highestValue) {
                highestValue = objecttosearch[index];
                key = index;
            }
        }
        return key;
    }

    /**
     * Calculates the top type
     * @method getTopType
     * @public
     * @memberof Choice
     * @param  {object} types   Object of types
     * @return {undefined}
     */
    getTopType(types) {
        if (!this.values)  {
            return;
        }

        var key = this.keyOfHighestValue(this.values);

        if (!key) {
            this.topType = '';
            return;
        }

        if (this.values.hasOwnProperty(key) && this.values[key] === 0) {
            this.topType = '-';
            return;
        }

        fast.forEach(types, (type) => {
            if (type.id === key) {
                this.topType = type.name;
            }
        }, this);

        return;
    }
}
