/**
 * @file
 * The frontend entry file. It appends the router and sets the transition group for page transistions between different states of the app.
 * @fileOverview
 * The frontend entry file
 * @module react/App
 * @author Joris Karl Dzaack <dzaack@laborb.de>
 * @copyright labor b designbüro, 2015
 */
'use strict';

import React from 'react/addons';
import Router from 'react-router';

let TransitionGroup = React.addons.CSSTransitionGroup;
let RouteHandler = Router.RouteHandler;

/**
 * @class App
 */
class App extends React.Component {
    /**
     * @constructs App
     */
    constructor(props) {
        super(props);
    }

    /**
     * Default render method
     * @return {JSX} Returns the App router
     */
    render() {
        var name = this.context.router.getCurrentPath();
        return (
            <TransitionGroup component="div" transitionName="app">
                <RouteHandler key={name} />
            </TransitionGroup>
        );
    }
}

App.contextTypes = {
  router: React.PropTypes.func
};

export default App;
