/**
 * @fileOverview fitText module.
 * @module utils/fitText
 * @author Joris Karl Dzaack <dzaack@laborb.de>
 * @copyright labor b designbüro, 2015
 */

'use strict';

import getComputedProperty from './getComputedProperty.js';
import setStyle from './setStyle.js';

/**
 * Port of fitText.js
 * @requires utils/getComputedProperty
 * @requires utils/setStyle
 * @param  {HTMLElement} element     The DOM Element to target
 * @param  {Number} [kompressor = 1]  compression multiplicator
 * @param  {Number} [minFontSize = Number.NEGATIVE_INFINITY] max font size
 * @param  {Number} [maxFontSize = Number.POSITIVE_INFINITY] min font size
 */
let fitText = function fitText(element, kompressor = 1, minFontSize = Number.NEGATIVE_INFINITY, maxFontSize = Number.POSITIVE_INFINITY) {
  let parent = element.parentElement;
  let parentWidth = parseInt(getComputedProperty(parent, 'width'),10);

  let fontSize = Math.min(Math.max(parentWidth / (element.textContent.length*kompressor), minFontSize), maxFontSize);

  setStyle(element, {
    'font-size': `${fontSize}px`
  });
};

export default fitText;
