/**
 * @fileOverview tokenReplacer module.
 * @module utils/tokenReplacer
 * @author Joris Karl Dzaack <dzaack@laborb.de>
 * @copyright labor b designbüro, 2015
 */
'use strict';

/**
 * Regular expression for finding tokens in the form of [TOKEN]
 * @private
 * @constant
 * @default
 * @type {RegExp}
 */
const re = /(\[\b[a-z]+\b\])/gmi;

/**
 * Replacer function for replacing matches with a value out of a map
 * @private
 * @param  {String} match the matching string
 * @param  {Map} map   the map containing the replacements
 * @return {String}       Replaced string
 */
const replaceMatch = (match, map) => {
  match = match.slice(1,-1);
  let needle = map.get(match);
  
  if(needle === void 0) {
    return '';
  }
  
  return needle;
};

/**
 * Token Replacer to replace tokens in the form of [TOKEN]
 * @param  {String} string string that will be changed
 * @param  {Map} map    map containing the replacements
 * @return {String}        string with the replacements
 */
export default function tokenReplacer (string, map) {
  return string.replace(re, function (match) {
    return replaceMatch(match, map);
  });
}
