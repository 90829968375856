/**
 * @file
 * Holds the BerufsFeldLinkList component.
 * @fileOverview
 * Holds the BerufsFeldLinkList component.
 * @module react/BerufsFeldLinkListItem
 * @author Joris Karl Dzaack <dzaack@laborb.de>
 * @copyright labor b designbüro, 2015
 */
'use strict';

import React from 'react/addons';
import fast from 'fast.js';

import marked from 'marked';

import Settings from '../frontend/models/Settings.js';
import Meta from '../frontend/models/Meta.js';

import setStyle from '../frontend/utils/setStyle.js';
import getStyle from '../frontend/utils/getStyle.js';

import tokenReplacer from '../frontend/utils/tokenReplacer.js';

import siteConfig from '../config/site.js';

function existy (what) {
  if( typeof what !== 'undefined' && what !== '') { 
    return true;
  }

  return false;
}

/**
 * BerufsFeldLinkListItem component
 * @classdesc The component for the "BerufsFeldLinkListItem".
 * @requires module:frontend/models/settings
 * @requires module:frontend/models/meta
 * @requires module:frontend/utils/getStyle
 * @requires module:frontend/utils/setStyle
 * @class BerufsFeldLinkListItem
 * @public
 */
class BerufsFeldLinkListItem extends React.Component {
  /**
   * The constructor for the BerufsFeldLinkListItem
   * @constructs BerufsFeldLinkListItem
   */
  constructor(props) {
      super(props);

      this.numOfLinks = 3;

      this.state = {
        links: [],
        more: '',
        cycle: 0,
        showMore: false
      }
  }

  /**
   * Fires immediately before the component mounts.
   * Sets the initial states of the compontent.
   */
  componentWillMount() {
      this.setState({
        links: Array.from(this.props.path.links),
        more: this.props.path.more
      });
  }

  /**
   * Filters all list entries.
   * Outputs only those who have a proper text and url.
   * @private
   * @param  {Array} links Array of links
   */
  _getListItems(links) {
    return fast.filter(links, (link) => {
      if(link.hasOwnProperty('text') && link.hasOwnProperty('url')) {
        if(existy(link.text) && existy(link.url)) {
          return true;
        }
      }

      return false;
    });
  }

  /**
   * Switches the display.
   * Shows different data for different states.
   * @private
   * @return {JSX} the component to show
   */
  _getDisplay() {
    if(this.state.showMore && this.state.more !== '') {
      return this._getMoreNode();
    }
    else {
      return this._getListNodes();
    }
  }

  /**
   * Display to be shown if all links have been seen
   * @private
   * @return {JSX} The JSX-DOM to show.
   */
  _getMoreNode() {
    let rawMarkup = tokenReplacer(marked(this.state.more, {sanitize: true}), siteConfig.replacements);
    return (<div className="berufsfeld-more__links__information">
      <div dangerouslySetInnerHTML={{__html: rawMarkup}} />
      <strong className="berufsfeld-more__links__information__links" onClick={fast.bind(this._resetCycle,this)}>Links anzeigen</strong>
    </div>);
  }

  /**
   * Returns a specific list nodes.
   * Splices the array of links to a specific portion to display.
   * @private
   * @return {JSX} The JSX-DOM to show.
   */
  _getListNodes() {
      let start = this.numOfLinks * (this.state.cycle);
      let end = start + this.numOfLinks;

      let listItems = this._getListItems(this.state.links);
      let listPortion = listItems.slice(start, end);

      let listNodes = fast.map(listPortion, (link, index) => {
        return (
          <li className="berufsfeld-more__links__list__item" key={index}>
            <a href={link.url} title={link.text} target="_blank">{link.text}</a>
          </li>
        );
      });

      let moreLink = () => {
        if(listItems.length > this.numOfLinks) {
          return (<strong className="berufsfeld-more__links__cycle" onClick={fast.bind(this._handleCycle,this)}>Andere anzeigen</strong>)
        }
        else if(listItems.length === this.numOfLinks && this.state.more !== '') {
          return (<strong className="berufsfeld-more__links__cycle" onClick={fast.bind(this._showMore,this)}>Andere anzeigen</strong>)
        }
        else {
          return;
        }
      }

      return (
        <div>
          <ul className="berufsfeld-more__links__list">
            {listNodes}
          </ul>
          { moreLink() }
        </div>
      );
  }

  /**
   * Helper method.
   * Sets the showMore state to true
   * @private
   */
  _showMore() {
    this.setState({
       showMore: true,
       cycle: 0
    });
  }

  /**
   * Helper method.
   * Resets the cycle of links.
   * @private
   */
  _resetCycle() {
    this.setState({
      showMore: false,
      cycle: 0
    });
  }

  /**
   * Event handler.
   * Iterates the cycle or resets it.
   * @private
   */
  _handleCycle() {
    let listItemsLength = this._getListItems(this.state.links).length;
    let currentStart = this.numOfLinks * (this.state.cycle);
    let potentialEnd = currentStart + this.numOfLinks;

    if(potentialEnd < listItemsLength){
      let newCycleValue = this.state.cycle +1;

      this.setState({
        cycle: newCycleValue
      });
    }
    else if (this.state.more === '') {
      this._resetCycle();
    }
    else {
      this._showMore();
    }
  }

  /**
   * Default render method
   */
  render() {
      let graphicsPath = siteConfig.paths.graphics;

      return (
          <div className="berufsfeld-more__links">
              <div className="berufsfeld-more__links__example">Beispiele für</div>
              <h4 className="berufsfeld-more__links__headline">{this.props.name}</h4>
              { this._getDisplay() }
              <img className="berufsfeld-more__links__image" src={graphicsPath+this.props.image} alt={this.props.name} />
              <a href={this.props.link} className="berufsfeld-more__links__more" target="_blank">Mehr Infos<br />zum Thema {this.props.name}</a>
          </div>
      );
  }
}

BerufsFeldLinkListItem.propTypes = {
    path: React.PropTypes.object,
    name: React.PropTypes.string,
    image: React.PropTypes.string,
    link: React.PropTypes.string
};
BerufsFeldLinkListItem.defaultProps = {
    path: {},
    name: '',
    image: '',
    link: ''
};

/**
 * BerufsFeldLinkList component
 * @classdesc The component for the "BerufsFeldLinkList".
 * @requires module:frontend/models/settings
 * @requires module:frontend/models/meta
 * @requires module:frontend/utils/getStyle
 * @requires module:frontend/utils/setStyle
 * @class BerufsFeldLinkList
 * @public
 */
class BerufsFeldLinkList extends React.Component {
  /**
   * The constructor for the BerufsFeldLinkListItem
   * @constructs BerufsFeldLinkList
   */
  constructor(props) {
    super(props);

    this.state = {
      settingsPaths: [],
      metaPaths: []
    }

    this._init();
  }

  /**
   * Method to initialize the class
   */
  _init() {
    this._loadAndSetSettings();
    this._loadAndSetMeta();
  }

  /**
   * Loads and sets the settings.
   * Sets the settingsPaths to the state.
   */
  _loadAndSetSettings() {
    let settings = new Settings();

    settings.load().then(() => {
      this.setState({
        settingsPaths: settings.paths
      });
    });
  }

  /**
   * Loads and sets the meta information.
   * Sets the metaPaths to the state.
   */
  _loadAndSetMeta() {
    let meta = new Meta(this.props.typeId);

    meta.load().then(() => {
        this.setState({
            'metaPaths': meta.data.paths
        });
    });
  }

  /**
   * Default render method
   */
  render() {
    let linkListItemNodes = fast.map(this.state.metaPaths, (path, index) => {
      let linkListName = this.state.settingsPaths[index] ? this.state.settingsPaths[index].name : '';
      let linkListImage = this.state.settingsPaths[index] ? this.state.settingsPaths[index].image : '';
      let linkListLink = this.state.settingsPaths[index] ? this.state.settingsPaths[index].link : '';
          
      return (<BerufsFeldLinkListItem path={path} name={linkListName} image={linkListImage} link={linkListLink} key={linkListName} />);
    });

    return (
        <div className="berufsfeld-more">
            {linkListItemNodes}
        </div>
    );
  }
}

BerufsFeldLinkList.propTypes = {
    typeId: React.PropTypes.string
};

export default BerufsFeldLinkList;
