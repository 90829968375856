/**
 * @fileOverview Meta module.
 * Gets all Meta-Information for a type id
 * @module frontend/models/meta
 * @author Joris Karl Dzaack <dzaack@laborb.de>
 * @copyright labor b designbüro, 2015
 */
'use strict';

import DataHandler from './DataHandler.js';

/**
 * Meta class
 * @classdesc Gets specific meta informations from an API call
 * @extends DataHandler
 * @see module:kompass/frontend/models/dataHandler
 * @class Meta
 * @public
 */
export default class Meta extends DataHandler {
    /**
     * Constructor for the Meta class
     * @constructs Meta
     * @param  {string} id ObjectId of type
     */
    constructor(id) {
        super();
        this.path = `/api/meta/${id}`;
    }

    /**
     * Loads and returns the choices array
     * @memberOf Meta
     * @return {promise} Promise after which the data is present in the instance
     * @override
     */
    load() {
        return super.load().then(() => {
            this.data = this.data;

            console.log(this.data);
        });
    }
}
