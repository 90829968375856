/**
 * @file
 * Holds the QuestionList component.
 * @fileOverview
 * Holds the QuestionList component.
 * @module react/QuestionList
 * @author Joris Karl Dzaack <dzaack@laborb.de>
 * @copyright labor b designbüro, 2015
 */
'use strict';

import React from 'react/addons';
import fast from 'fast.js';

import Question from './Question.jsx';

import Settings from '../frontend/models/Settings.js';

let TransitionGroup = React.addons.TransitionGroup;

/**
 * QuestionList component
 * @classdesc The component for the "QuestionList".
 * @requires module:react/Question
 * @requires module:frontend/models/settings
 * @class QuestionList
 * @public
 */
class QuestionList extends React.Component {
  /**
   * Constructor for QuestionList
   * @constructs QuestionList
   */
  constructor(props) {
      super(props);

      this.state = {
        text: ''
      }

      this._loadAndSetSettings();
  }

  /**
   * Bubbles the choice to another component.
   * @private
   */
  _handleChoice(choice) {
      this.props.onChoose(choice);
  }

  /**
   * Initialises the class.
   * Loads and sets the settings from the database.
   * @private
   */
  _loadAndSetSettings() {
    let settings = new Settings();

    settings.load().then(() => {
      this.setState({
        text: settings.question
      });
    });
  }

  /**
   * Default render method
   */
  render() {
      var questionNodes = fast.map(this.props.questions, (question, index) => {
          return (
              <li className="main__questions__list__item" key={question.id} id={`question${index}`}>
                  <Question text={this.state.text} question={question} onChoose={fast.bind(this._handleChoice,this)} id={index} count={this.props.count} />
              </li>
          );
      });

      return (
          <TransitionGroup component="ol" className="main__questions__list">
              {questionNodes}
          </TransitionGroup>
      );
  }
}
QuestionList.propTypes = {
  questions: React.PropTypes.array,
  onChoose: React.PropTypes.func.isRequired,
  count: React.PropTypes.number
};
QuestionList.defaultProps = {
  questions: [],
  count: 0
};

export default QuestionList;
