/**
 * @file
 * Holds the Result component.
 * @fileOverview
 * Holds the Result component.
 * @module react/Result
 * @author Joris Karl Dzaack <dzaack@laborb.de>
 * @copyright labor b designbüro, 2015
 */
'use strict';

import React from 'react/addons';

import fast from 'fast.js';

import ResultBillboard from './ResultBillboard.jsx';
import BerufsFeldLinkList from './BerufsFeldLinkList.jsx'

import Settings from '../frontend/models/Settings.js';

import marked from 'marked';
import tokenReplacer from '../frontend/utils/tokenReplacer.js';

import addClass from 'amp-add-class';
import removeClass from 'amp-remove-class';

import siteConfig from '../config/site.js';

import ImageMap from '../frontend/utils/ImageMap.js';
import ExternalLinksInNewWindow from '../frontend/utils/externalLinksInNewWindow.js';

import { Link } from 'react-router';

/**
 * Result component
 * @classdesc The component for the "Questions".
 * @requires module:react/QuestionsList
 * @requires module:react/Result
 * @requires module:frontend/models/Settings
 * @requires module:frontend/utils/ImageMap
 * @requires module:frontend/utils/externalLinksInNewWindow
 * @requires module:frontend/utils/tokenReplacer
 * @requires module:config/site
 * @class Result
 * @public
 */
class Result extends React.Component {
  /**
   * Constructor for the Result class
   * Sets the default values.
   * Instanciates the ImageMap class.
   * @constructs Result
   */
  constructor(props) {
      super(props);

      this.router = {};
      this.params = {};

      this.state = {
        result: '',
        footer: ''
      };

      this.imageMap = new ImageMap('(max-width: 1050px)');
      this._init();
  }

  /**
   * Fired after the component has been mounted
   * Fires the ImageMap instance.
   * Converts all links on the page to open in an external window.
   */
  componentDidMount() {
    window.scrollTo(0, 0);
    this.imageMap.fire()
    ExternalLinksInNewWindow.convert();
  }

  /**
   * Is called when the component gets updated
   * Fires the ImageMap instance again.
   * Converts all links on the page to open in an external window again.
   */
  componentDidUpdate() {
    this.imageMap.fire();
    ExternalLinksInNewWindow.convert();
  }

  /**
   * Is fired before the component mounts.
   * Sets the params to the instance of the class
   * Adds a class to the body for specific styling.
   */
  componentWillMount() {
    this.router = this.context.router;
    this.params = this.router.getCurrentParams();

    addClass(document.body, 'isResult');
  }

  /**
   * Fires before the component unmounts.
   * Removes the class from the body.
   */
  componentWillUnmount() {
    removeClass(document.body, 'isResult');
  }

  /**
   * Initializes the class.
   * @private
   */
  _init() {
    this._loadAndSetSettings();
  }

  /**
   * Loads and sets the settings.
   * Saves the settings to the state. 
   */
  _loadAndSetSettings() {
    let settings = new Settings();

    settings.load().then(() => {
      console.log(settings);
      this.setState({
        footer: settings.footer,
        result: settings.result
      });
    });
  }

  /**
   * Handles the Facebook share dialog.
   * Opens the facebook share dialog in a new window.
   * @deprecated No longer used due to design changes.
   * @private
   */
  _handleShare() {
    FB.ui({
      method: 'share',
      href: window.location.href,
    }, function(response){
      console.log(response);
    });
  }

  /**
   * Default render method.
   */
  render() {
    let rawResult = tokenReplacer(marked(this.state.result, {sanitize: true}), siteConfig.replacements);
    let rawContent = this.state.footer;
    return (
      <div className="results">
        <div className="results__result">
            <ResultBillboard gender={this.params.gender} persoenlichkeitId={this.params.persoenlichkeit} berufId={this.params.beruf} />
            <div className="results__result__text" dangerouslySetInnerHTML={{__html: rawResult}} />
            <BerufsFeldLinkList typeId={this.params.beruf} />
        </div>
        <div className="results__meta">
          <div className="results__meta__share">
              <h4 className="results__meta__share__headline">Noch einmal testen? Kein Problem:</h4>
              <a href="/" className="results__meta__share__btn">Neu starten</a>
          </div>
          <div className="results__meta__zukunftswege">
              <div className="results__meta__zukunftswege__text">
                  Dir fehlt noch der richtige Abschluss für dein Ziel?<br />
                  Schaue dir <a href="http://zukunftsfinder.de/zukunftswege">hier</a> deine Möglichkeiten an – mit den <a href="http://zukunftsfinder.de/zukunftswege">Zukunftswegen</a>.
              </div>
          </div>
        </div>
        <footer className="results__footer footer" dangerouslySetInnerHTML={{__html: rawContent}} />
      </div>
    );
  }
}

Result.contextTypes = {
  router: React.PropTypes.func
};

export default Result;
