/**
 * @file
 * Holds the Custscene component.
 * @fileOverview
 * Holds the Custscene component.
 * @module react/Custscene
 * @author Joris Karl Dzaack <dzaack@laborb.de>
 * @copyright labor b designbüro, 2015
 */
'use strict';

import React from 'react/addons';
import Router from 'react-router';

import fast from 'fast.js';

import addClass from 'amp-add-class';
import removeClass from 'amp-remove-class';

import setStyle from '../frontend/utils/setStyle.js';

import siteConfig from '../config/site.js';

let { Link } = Router;

/**
 * Cutscene component
 * @classdesc The component for the "Cutscene".
 * @requires module:frontend/utils/setStyle
 * @requires module:config/site
 * @class Choice
 * @public
 */
class Cutscene extends React.Component {
  /**
   * Cutscene constructor.
   * @constructs Cutscene
   */
  constructor(props) {
      super(props);
      this.router = {};
      
      this.timelines = {
         papierflieger: new TimelineMax({repeat:-1}),
         rakete: new TimelineMax({delay:2})
      };

      this.state = {
        gender: ''
      }
  }

  /**
   * Animates the "Papierflieger"
   * @private
   */
  _animatePapierflieger() {
    let papierflieger = document.getElementsByClassName('cutscene__papierflieger')[0];

    this.timelines.papierflieger
      .fromTo(papierflieger, 6, {ease: Power1.easeInOut, top: '50px', left: '-20%'}, {ease: Circ.easeInOut, top:'100px', left:'20%'})
      .to(papierflieger, 6, {ease: Power1.easeInOut, top:'150px', left:'50%'})
      .to(papierflieger, 6, {ease: Power1.easeInOut, top:'50px', left:'120%'});
  }

  /**
   * Animates the whole cutscene and fires a callback when done.
   * @param  {Function} callback Callback
   */
  _animateCutscene(callback) {
    let rakete = {
      initial:  document.getElementsByClassName('header__content__rakete--initial')[0],
      finish:  document.getElementsByClassName('header__content__rakete--finish')[0]
    };
    let person = document.getElementsByClassName('header__content__person')[0];

    this.timelines.rakete
      .to(rakete.initial, 1.2, {ease: Circ.easeOut, css:{autoAlpha:0}}, 'boarding')
      .to(rakete.finish, 1.2, {ease: Circ.easeOut, css:{autoAlpha:1}}, 'boarding')
      .to(person, 1.2, {ease: Circ.easeOut, css:{autoAlpha:0}}, 'boarding')
      .add('boarding')
      .to(rakete.finish, 2, {ease: Circ.easeOut, top: '-150%', onComplete: callback});
  }

  /**
   * Fired immediately before a component mounts
   * Sets the gender to the state.
   * Adds a class to the body for applying specific css to the view.
   */
  componentWillMount() {
    this.router = this.context.router;
    
    let params = this.router.getCurrentParams();

    this.setState({
      gender: params.gender
    });

    addClass(document.body, 'isCutscene');
  }

  /**
   * Fires when component did mount
   * Starts various animations.
   */
  componentDidMount() {
    this._animatePapierflieger();
    
    this._animateCutscene(() => {
      this.router.transitionTo('questions', { gender: this.state.gender });
    });

    window.scrollTo(0, 0);
  }

  /**
   * Fired before a component is unmounted.
   * Removes classes and sets styles for a smoother transition betweens views.
   */
  componentWillUnmount() {
    const headerDOM = React.findDOMNode(this.refs.header);

    removeClass(document.body, 'isCutscene');

    setStyle(headerDOM, {
      display: 'none'
    });
  }

  /**
   * Default render method
   */
  render() {
      const graphicsPath = siteConfig.paths.graphics;

      let figur = fast.bind(function(graphicsPath) {
        let image = this.state.gender === 'male' ? 'start-cut-male.png' : 'start-cut-female.png';
        return (<img src={graphicsPath+image} className="header__content__person" />);
      },this)(graphicsPath);

      let raketeInitial = fast.bind(function(graphicsPath) {

        let image = this.state.gender === 'male' ? 'drill-2x-prestart.png' : 'drill-2x-prestart.png';
        return (<img src={graphicsPath+image} className="header__content__rakete header__content__rakete--initial" />);
      },this)(graphicsPath);

      let raketeFinish = fast.bind(function(graphicsPath) {
        let imageClassName = this.state.gender === 'male' ? 'header__content__rakete__male' : 'header__content__rakete__female';
        imageClassName += ' header__content__rakete--finish';
        return (<span className={imageClassName}></span>);
      },this)(graphicsPath);

      return (
        <div className="cutscene">
          <header className="header" ref="header">
            <div className="header__content">
              {raketeInitial}
              {raketeFinish}
              {figur}
            </div>
          </header>
          <img src="/public/frontend/gfx/papierflieger.png" className="cutscene__papierflieger" />
        </div>
      );
  }
}

Cutscene.contextTypes = {
  router: React.PropTypes.func
};

export default Cutscene;
