'use strict';

import React from 'react/addons';
import Router from 'react-router';

let DefaultRoute = Router.DefaultRoute;
let Route = Router.Route;
let NotFoundRoute = Router.NotFoundRoute;

import App from '../../react/App.jsx';

import Home from '../../react/Home.jsx';
import Cutscene from '../../react/Cutscene.jsx';
import Questions from '../../react/Questions.jsx';
import Result from '../../react/Result.jsx';

const routes = (
  <Route handler={App} path="/" location={history.pushState ? 'history' : 'hash'}>
    <DefaultRoute name="home" handler={Home} />
    <Route name="cutscene" path="cutscene/:gender" handler={Cutscene} />
    <Route name="questions" path="test/:gender" handler={Questions} />
    <Route name="result" path="result/:gender/:beruf/:persoenlichkeit" handler={Result} />
    <NotFoundRoute handler={Home}/>
  </Route>
);

export default routes;
