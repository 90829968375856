/**
 * @file
 * Holds the Choice component.
 * @fileOverview
 * Holds the Choice component.
 * @module react/Choice
 * @author Joris Karl Dzaack <dzaack@laborb.de>
 * @copyright labor b designbüro, 2015
 */
'use strict';

import React from 'react/addons';
import fast from 'fast.js';

import classNames from 'classnames';

import stripHtml from '../frontend/utils/stripHtml.js';

/**
 * Choice component
 * @classdesc The component for the "Berufsfeld". It writes the result's name to the page.
 * @requires module:frontend/utils/stripHtml
 * @class Choice
 * @public
 */
class Choice extends React.Component {
  /**
   * Choice constructor
   * @constructs Choice
   */
  constructor(props) {
      super(props);

      this.classes = new Set(['choices__choice']);

      this.state = {
          classes: classNames(...this.classes)
      };
  }

  /**
   * Adds a class.
   * Adds a class to the state
   * @private
   * @param {string} clssNm Name of the class
   */
  _addClass(clssNm) {
    this.classes.add(clssNm);
    this.setState({
        classes: classNames(...this.classes)
    });
  }

  /**
   * Removes a class.
   * Removes a class from the state.
   * @private
   * @param  {strings} clssNm Name of the class that will be removed
   */
  _removeClass(clssNm) {
    this.classes.delete(clssNm);
    this.setState({
        classes: classNames(...this.classes)
    });
  }

  /**
   * Event Handler.
   * This event handler is fired when a choice is choosen
   * @private
   * @param  {Event} e Event
   */
  _handleChoose(e) {
      if(!this.props.answered){
          this._addClass('chosen');
          this.props.onChoose(this.props.choice);
      }
  }

  /**
   * Handles the events.
   * Handles all events added to an event listener.
   * @private
   * @param  {Event} e Event
   */
  _handleEvent(e) {
    if(!this.props.answered){
      switch(e.type) {
        case 'keydown':
          if(e.keyCode === this.props.keyHandler) {
            //this._addClass('active');
            document.removeEventListener('keydown', this, false);
          }
        break;
        case 'keyup':
          if(e.keyCode === this.props.keyHandler) {
            //this._removeClass('active');
            this._handleChoose(e);
            document.removeEventListener('keyup', this, false);
          }
        break;
      }
    }
  }

  /**
   * Event handler
   * Handles all events.
   * Passes the event to the private function.
   */
  handleEvent(event) {
    this._handleEvent(event)
  }

  /**
   * Fires immediately before the component is mounted.
   * Adds event listeners. 
   */
  componentWillMount() {
    document.addEventListener('keydown', this, false);
    document.addEventListener('keyup', this, false);
  }

  /**
   * Default render method.
   */
  render() {
      return (
          <div className={this.state.classes} >
              <strong className="choices__choice__step">Schritt {this.props.id+1} / {this.props.count}</strong>
              <div className="choices__choice__id">
                  {this.props.choice.id}
              </div>
              <div className="choices__choice__text" onClick={fast.bind(this._handleChoose,this)}>
                  <div className="choices__choice__text__inner">
                      <span className="choices__choice__step">Schritt {this.props.id+1} / {this.props.count}</span>
                      <strong dangerouslySetInnerHTML={{__html: stripHtml(this.props.choice.text) }} />
                  </div>
              </div>
          </div>
      );
  }
}

Choice.propTypes = {
    choice: React.PropTypes.object.isRequired,
    onChoose: React.PropTypes.func.isRequired,
    answered: React.PropTypes.bool.isRequired,
    keyHandler: React.PropTypes.number.isRequired,
    count: React.PropTypes.number.isRequired,
    id: React.PropTypes.number.isRequired
};

export default Choice;
