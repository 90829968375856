/**
 * @fileOverview Meta module.
 * Gets all Meta-Information for a type id
 * @module frontend/models/questions
 * @author Joris Karl Dzaack <dzaack@laborb.de>
 * @copyright labor b designbüro, 2015
 */
'use strict';

import DataHandler from './DataHandler.js';
import Question from './Question.js';

/**
 * Questions class
 * @classdesc Gets all questions the database by an API call
 * @extends DataHandler
 * @requires module:frontend/models/question
 * @see module:frontend/models/dataHandler
 * @class Questions
 * @public
 */
export default class Questions extends DataHandler {
  /**
   * Constructor for the Questions class
   * @constructs Questions
   */
  constructor() {
    super();
    this.path = '/api/questions';
  }

  /**
   * Returns a specific question by an id
   * @memberOf Questions
   * @param  {number} index the questions index
   * @return {object}       the questions object
   */
  getForIndex(index) {
    return this.data[index];
  }

  /**
   * Getter for the lenght of all questions
   * @memberOf Questions
   * @return {number} questions length
   */
  get length() {
    return this.data.length;
  }

  /**
   * Loads and returns an array of questions objects
   * @memberOf Questions
   * @see kompass/frontend/models/question
   * @return {promise} Promise after which the data is present in the instance
   * @override
   */
  load() {
    return super.load().then(() => {
      this.data = this.data.map((type) => {
        return new Question(type);
      });
    });
  }
}
