/**
 * @file
 * Holds the Home component.
 * @fileOverview
 * Holds the Home component.
 * @module react/Home
 * @author Joris Karl Dzaack <dzaack@laborb.de>
 * @copyright labor b designbüro, 2015
 */
'use strict';

import React from 'react/addons';
import Router from 'react-router';

import fast from 'fast.js';
import marked from 'marked';

import addClass from 'amp-add-class';
import removeClass from 'amp-remove-class';

import Settings from '../frontend/models/Settings.js';

import tokenReplacer from '../frontend/utils/tokenReplacer.js';
import stripHtml from '../frontend/utils/stripHtml.js';

import siteConfig from '../config/site.js';

let Link = Router.Link;

/**
 * Home component
 * @classdesc The component for the "Home" view.
 * @requires module:frontend/modesl/settings
 * @requires module:frontend/utils/tokenReplacer
 * @requires module:frontend/utils/stripHtml
 * @requires module:config/site
 * @class Home
 * @public
 */
class Home extends React.Component {
  /**
   * Contructs Home
   * Sets the timelines and default state.
   * @constructs Home
   */
  constructor(props) {
    super(props);

    this.router = {};
    this.timelines = {
       papierflieger: new TimelineMax({repeat:-1}),
       rakete: new TimelineMax()
    };

    this.state = {
      callout: '',
      headline: ''
    }

    this._loadAndSetSettings();
  }

  /**
   * Loads the settings from the database.
   * Stores the settings in the state of the component.
   * @private
   */
  _loadAndSetSettings() {
    let settings = new Settings();

    settings.load().then(() => {
      this.setState({
        callout: settings.callout,
        headline: settings.headline
      });
    });
  }

  /**
   * Animates the "Papierflieger"
   * @private
   */
  _animatePapierflieger() {
    let papierflieger = React.findDOMNode(this.refs.papierflieger);

    this.timelines.papierflieger
      .fromTo(papierflieger, 6, {ease: Power1.easeInOut, top: '50px', left: '-20%'}, {ease: Circ.easeInOut, top:'100px', left:'20%'})
      .to(papierflieger, 6, {ease: Power1.easeInOut, top:'150px', left:'50%'})
      .to(papierflieger, 6, {ease: Power1.easeInOut, top:'50px', left:'120%'});
  }

  /**
   * Transitions to gender specific version of the questions
   * @private
   */
  _handleStart(gender) {
    this.router.transitionTo('cutscene', { gender: gender });
  }

  /**
   * Handles all events.
   * Handles the keypresses to directly transition to the cutscene/questions.
   * @private
   */
  _handleEvent(event) {
    if(event.type === 'keydown') {
      window.removeEventListener('keydown', this, false);
      
      switch(event.keyCode) {
        case 65: //A pressed
          this._handleStart('male');
        break;
        case 66: //B pressed
          this._handleStart('female');
        break;
      }
    }
  }

  /**
   * Handles all events.
   * Passes the event to the private function. 
   */
  handleEvent(event) {
    this._handleEvent(event);
  }

  /**
   * Fires immediately before a component is mounted.
   * Adds event listeners to keyboard input.
   * Adds a class to the body for specific styles.
   */
  componentWillMount() {
    this.router = this.context.router;
    
    window.addEventListener('keydown', this, false);
    addClass(document.body, 'isHome');
  }

  /**
   * Fired immediately before a component is unmounted.
   * Removes the event listeners and the the body class.
   */
  componentWillUnmount() {
    window.removeEventListener('keydown', this, false);
    removeClass(document.body, 'isHome');
  }

  /**
   * Is fired when the component did mount.
   * Starts the animation of the Papierflieger.
   */
  componentDidMount() {
    this._animatePapierflieger();
    window.scrollTo(0, 0);
  }

  /**
   * Default render method
   */
  render() {
    const graphicsPath = siteConfig.paths.graphics;
    let rawCallout = tokenReplacer(marked(this.state.callout, {sanitize: true}), siteConfig.replacements);
    let rawHeadline = tokenReplacer(stripHtml(this.state.headline), siteConfig.replacements);
    
    return (
      <div className="home">
        <header className="header">
          <div className="header__content">
            <h1 className="header__content__headline" dangerouslySetInnerHTML={{__html: rawHeadline}} />
            <div className="header__content__text" dangerouslySetInnerHTML={{__html: rawCallout}} />
          </div>
        </header>
        <ul className="start">
          <li className="start__link start__link--male">
            <span className="start__link__image start__link__image--male" onClick={fast.bind(this._handleStart,this, 'male')}></span>
          </li>
          <li className="start__link start__link--female">
            <span className="start__link__image start__link__image--female" onClick={fast.bind(this._handleStart,this, 'female')}></span>
          </li>
        </ul>
        <img src="/public/frontend/gfx/papierflieger.png" className="home__papierflieger" ref="papierflieger" />
      </div>
    );
  }
}

Home.contextTypes = {
  router: React.PropTypes.func
};


export default Home;
