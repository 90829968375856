/**
 * @fileOverview ExternalLinksInNewWindow module.
 * @module utils/ExternalLinksInNewWindow
 * @author Joris Karl Dzaack <dzaack@laborb.de>
 * @copyright labor b designbüro, 2015
 */

'use strict';

import {filter, forEach} from 'fast.js';

/**
 * ExternalLinksInNewWindow class
 * @class ExternalLinksInNewWindow
 * @public
 */
export default class ExternalLinksInNewWindow {
  /**
   * Gets all links on the page and forces the external one to open in a new window
   * @static
   */
  static convert() {
    const links = document.getElementsByTagName('a');
    const linksWithExternalURL = filter([].slice.call(links), (a) => {
      return a.hostname && a.hostname !== location.hostname;
    });

    forEach(linksWithExternalURL, (a) => {
      let rel = a.getAttribute('rel');

      if(!rel || rel.indexOf('external') === -1) {
        a.setAttribute('rel', 'external');

        a.addEventListener('click', (event) => {
           event.preventDefault();
           event.stopPropagation();
           window.open(a.href, '_blank');
        }, false);
      }
    });
  }
}
