/**
 * @fileOverview Settings module
 * Gets all settings by an API call
 * @module frontend/models/settings
 * @author Joris Karl Dzaack <dzaack@laborb.de>
 * @copyright labor b designbüro, 2015
 */
'use strict';

import DataHandler from './DataHandler.js';

/**
 * Settings class
 * @classdesc Gets all settings the database by an API call
 * @extends DataHandler
 * @see module:frontend/models/dataHandler
 * @class Settings
 * @public
 */
export default class Settings extends DataHandler {
    /**
     * Constructor for the Settings class
     * @memberOf Settings
     * @constructs Settings
     */
    constructor() {
        super();
        this.path = '/api/settings';
    }

    /**
     * Loads and return the settings
     * @method load
     * @memberOf Settings
     * @return {promise} Promise after which the data is present in the instance
     * @override
     */
    load() {
        return super.load().then(() => {
            this.phaseTwoCount = [
                this.data.phaseTwoCount.matrix0,
                this.data.phaseTwoCount.matrix1
            ];

            this.paths = this.data.paths;
            this.callout = this.data.callout;
            this.headline = this.data.headline;
            this.question = this.data.question;
            this.result = this.data.result;
            this.footer = this.data.footer;

            delete this.data;
            delete this.path;
        });
    }
}
