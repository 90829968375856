/**
 * @fileOverview Site configuration
 * @module config/site
 * @type {Object}
 * @author Joris Karl Dzaack <dzaack@laborb.de>
 * @copyright labor b designbüro, 2015
 */

export default {
  'paths': {
    'graphics': '/public/frontend/gfx/', 
    'types': '/public/types/'
  },
	'linkListNames': [
    {
      'name': 'Studiengänge',
      'image': 'karte-05-std-off.png',
      'link': 'http://zukunftsfinder.de/kategorie/zukunftsfinder-kompass-studium'
    },
    {
      'name': 'Ausbildungen',
      'image': 'karte-05-asbd-off.png',
      'link': 'http://zukunftsfinder.de/kategorie/zukunftsfinder-kompass-ausbildung'
    },
    {
      'name': 'Beufskollegs',
      'image': 'karte-05-brk-off.png',
      'link': 'http://zukunftsfinder.de/kategorie/zukunftsfinder-kompass-berufskolleg'
    },
    {
      'name': 'Freiwilliges Jahr',
      'image': 'karte-05-fwj-off.png',
      'link': 'http://zukunftsfinder.de/kategorie/zukunftsfinder-kompass-freiwilliges-jahr'
    }
  ],
  'replacements': new Map([
    ['shy', '&shy;'],
    ['nbsp', '&nbsp;'],
    ['br', '<br />']])
};
