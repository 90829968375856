/**
 * @fileOverview Data Handler.
 * @module frontend/models/data
 * @author Joris Karl Dzaack <dzaack@laborb.de>
 * @copyright labor b designbüro, 2015
 */

'use strict';

import Types from './Types.js';
import Choices from './Choices.js';
import Questions from './Questions.js';
import Settings from './Settings.js';

/**
 * Data class
 * @classdesc Returns all data necessary for the applications
 * @requires module:frontend/models/types
 * @requires module:frontend/models/choices
 * @requires module:frontend/models/questions
 * @requires module:frontend/models/settings
 * @class Data
 * @public
 */
export default class Data {
    /**
     * Constructor of the Data class
     * @memberOf Data
     * @constructs Data
     */
    constructor() {
        this.types = new Types();
        this.choices = new Choices();
        this.questions = new Questions();
        this.settings = new Settings();
    }

    /**
     * Returns a promise; after its resolving, all data necessary is present in the instance
     * @memberOf Data
     * @return {promise} Promise
     */
    load() {
        return Promise.all([
            this.types.load(),
            this.choices.load(),
            this.questions.load(),
            this.settings.load()
        ]).then(() => {
            for (let choice of this.choices) {
                choice.calcValues(Array.from(this.types));
                choice.getTopType(Array.from(this.types));
            }

            for (let question of this.questions) {
                question.getData(this.choices);
            }
        });
    }
}
