/**
 * @fileOverview setStyle module.
 * @module utils/setStyle
 * @author Joris Karl Dzaack <dzaack@laborb.de>
 * @copyright labor b designbüro, 2015
 */
'use strict';

/**
 * Sets the style of a DOM element
 * @param {HTMLElement}   element     The HTML element to style
 * @param {object}   declaration object containing the declarations and properties
 * @param {Function} callback    Callback function
 */
export default function setStyle (element, declaration, callback)
{
    for (let property in declaration) {
        if(declaration.hasOwnProperty(property)) {
            element.style.setProperty(property, declaration[property], 'important');
        }
    }

    if(callback) {
        callback.call();
    }
}
