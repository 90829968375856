/**
 * @file
 * Holds the Questions component.
 * @fileOverview
 * Holds the Questions component.
 * @module react/Questions
 * @author Joris Karl Dzaack <dzaack@laborb.de>
 * @copyright labor b designbüro, 2015
 */
'use strict';

import React from 'react/addons';
import Router from 'react-router';

import fast from 'fast.js';

import Data from '../frontend/models/Data.js';
import PhaseTwo from '../frontend/models/PhaseTwo.js';
import Intermediate from '../frontend/models/Intermediate.js';

import QuestionList from './QuestionList.jsx';
import Result from './Result.jsx';

import addClass from 'amp-add-class';
import removeClass from 'amp-remove-class';

import smoothScroll from '../frontend/utils/smoothScroll.js';
import backgroundPosition from '../frontend/utils/backgroundPosition.js';
import setStyle from '../frontend/utils/setStyle.js';

let transitionTo = Router.transitionTo;

/**
 * Returns the height of the document.
 * Used when scrolling to the end of the page.
 */
var getDocumentHeight = function () {
  return Math.max(
    document.body.scrollHeight, document.documentElement.scrollHeight,
    document.body.offsetHeight, document.documentElement.offsetHeight,
    document.body.clientHeight, document.documentElement.clientHeight
  );
};

/**
 * Questions component
 * @classdesc The component for the "Questions".
 * @requires module:react/QuestionsList
 * @requires module:react/Result
 * @requires module:frontend/models/Data
 * @requires module:frontend/models/PhaseTwo
 * @requires module:frontend/models/Intermediate
 * @requires module:frontend/utils/smoothScroll
 * @requires module:frontend/utils/backgroundPosition
 * @requires module:frontend/utils/setStyle
 * @class Questions
 * @public
 */
class Questions extends React.Component {
  /**
   * Constructor for Questions
   * Sets the default values. 
   * Sets the default state.
   * Adds the timelines to the instance.
   * @constructs Questions
   */
  constructor(props) {
      super(props);
      this.step = 0;
      this.phase = 0;
      this.data = new Data();
      this.intermediate = new Intermediate();
      this.phaseTwo = new PhaseTwo();
      this.router = {};
      this.params = {};
      this.state = {
          'test': props.test,
          'totalQuestionCount': 0
      };
      this.timelines = {
        final: new TimelineMax({delay:0})
      };
  }

  /**
   * Adds a new question to the state.
   * @private
   * @param {Question} question The new question.
   */
  _addQuestion(question) {
    var newState = React.addons.update(this.state, {
      test : {
        $push : [question]
      }
    });

    this.setState(newState);
  }

  /**
   * Is fired when a question is clicked.
   * Manages the start of phase two.
   * Manages the end of questoins.
   * Manages intermediate questions.
   * @private
   * @param  {Choice} choice The chosen choice.
   */
  _handleChoice(choice) {
      this.intermediate.choices = choice;

      // Phase 2 begins
      if(this.data.questions.length <= this.step+1 && this.phase === 0) {
          this.phase = 1;

          this.phaseTwo.calculate(this.data, this.intermediate);
      }

      // End of Questions
      if(this.phaseTwo.questions.length <= (this.step+1 - this.data.questions.length) && this.phase === 1) {
        this._endOfQuestionaire();
      }

      //second last question
      if(this.phaseTwo.questions.length-1 === (this.step+1 - this.data.questions.length) && this.phase === 1) {
        this._secondLastQuestion();
      }

      // Add questions to questions array
      if(this.data.questions.length-1 >= this.step+1) { // Questions for phase 1
          this.step++;

          this._addQuestion(this.data.questions.getForIndex(this.step));
      }
      else if(this.phaseTwo.questions.length-1 >= (this.step+1 - this.data.questions.length)) { // Questions for phase 2
          this.step++;

          let phaseTwoStep = this.step - this.data.questions.length;
          this._addQuestion(this.phaseTwo.getForIndex(phaseTwoStep));
      }
  }

  /**
   * Fired when the second last question is reached.
   * @deprecated Not longer used or necessary.
   */
  _secondLastQuestion() {

  }

  /**
   * Called when all questions have been answered. 
   * Handles the final animations.
   * Handles the transition.
   * @private
   */
  _endOfQuestionaire() {
    let gender = this.params.gender || 'male';
    let beruf = this.intermediate.choices[0][0];
    let persoenlichkeit = this.intermediate.choices[1][0];

    let cloudsDOM = React.findDOMNode(this.refs.clouds);

    let currentHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

    setStyle(cloudsDOM, {
      height: `${currentHeight}px`
    });

    addClass(cloudsDOM, 'active');

    this._animateFinal(
      () => {
        const flightPathDom = React.findDOMNode(this.refs.flightpath);

        setStyle(flightPathDom, {
          display: 'none'
        });
      },
      () => {
      window.setTimeout(() => {
        const tunnelDOM = React.findDOMNode(this.refs.tunnel);
        const headerDOM = React.findDOMNode(this.refs.header);

        setStyle(headerDOM, {
          display: 'none'
        });

        setStyle(tunnelDOM, {
          display: 'none'
        }, () => {
          this.router.transitionTo('result', { gender: gender, beruf: beruf, persoenlichkeit: persoenlichkeit });
        });
        
      }, 3000);
    });
  }

  /**
   * Handles the initial state/animation of the rocket.
   * @private
   * @deprecated Not longer used or necessary due to design change.
   */
  _animateRaketeInitial(callback) {}

  /**
   * Handles the final animations
   * @private
   */
  _animateFinal(scrollCallback, finalCallback) {
    const cloudsDOM = React.findDOMNode(this.refs.clouds);
    const tunnelDOM = React.findDOMNode(this.refs.tunnel);
    const flightPathDOM = React.findDOMNode(this.refs.flightpath);
    const flightPathRaketeDOM = React.findDOMNode(this.refs.rakete);
    const doorLeftDOM = React.findDOMNode(this.refs.doorLeft);
    const doorRightDOM = React.findDOMNode(this.refs.doorRight);

    let flightPathRaketeDOMHeight = parseInt(window.getComputedStyle(flightPathRaketeDOM).height,10);
    let docHeight = getDocumentHeight();

    //var curScroll = {x:getScrollX(), y:getScrollY()};

    addClass(flightPathRaketeDOM, 'drilling');
    addClass(tunnelDOM, 'final');
    addClass(flightPathDOM, 'final');

    this.timelines.final
      .to([tunnelDOM, flightPathDOM], 2, {ease: Sine.easeInOut, css:{height: `${docHeight-flightPathRaketeDOMHeight}px`}},'docking')
      .to(window, 4, {ease: Sine.easeInOut, scrollTo:{y:docHeight, autoKill:false}, onComplete: scrollCallback},'docking')
      .add('docking')
      .to([doorLeftDOM], 2, {ease: Sine.easeInOut, css:{marginLeft: '-98px'}},'doors')
      .to([doorRightDOM], 2, {ease: Sine.easeInOut, css:{marginLeft: '-5px'}, onComplete: finalCallback},'doors')
      .add('doors');
  }

  /**
   * Scrolls the view to the last question.
   * @private
   */
  _scrollToLastQuestion() {
    let lastQuestionByIndex = this.state.test.length-1;
    smoothScroll.animateScroll(null, `#question${lastQuestionByIndex}`, {speed: 500, "updateURL": false, easing: 'easeInOutQuint'});
  }

  /**
   * Calculates the estimated total question count.
   * The question max question count is shown to the user.
   */
  _calculateTotalQuestionCount() {
    let totalPhaseTwoCount = this.data.settings.phaseTwoCount.reduce((a,b) => a + b, 0);

    this.setState({
      'totalQuestionCount': this.data.questions.length + totalPhaseTwoCount
    });
  }

  /**
   * Removes one question
   * @deprecated Deprecated due to design change
   */
  _previousQuestion() {
    if(this.step < 1) {
      return;
    }

    this.step -= 1;

    this.intermediate.removeLastQuestion();

    var newState = React.addons.update(this.state, {
        test : {
            $splice : [[0,this.state.test.length-2]]
        }
    });

    this.setState(newState);
  }

  /**
   * Adds a gender specific class to the rocket
   * @private
   */
  _handleRakete(rakete) {
    const raketeDOM = React.findDOMNode(rakete);
    addClass(raketeDOM, this.params.gender || 'male');
  }

  /**
   * Handles keypresses from the keyboard
   */
  _handleKeypress(e) {
    let event = window.event ? window.event : e;

    if(event.keyCode === 38) {
      event.preventDefault();
      this._previousQuestion();
    }
  }

  /**
   * Handles events and fires different functions for each event.
   * @private
   */
  _handleEvent(event) {
    switch(event.type) {
      case 'resize':
      case 'orientationchange':
        this._scrollToLastQuestion();
      break;
      case 'keydown':
        this._handleKeypress(event);
      break;
    }
  }

  /**
   * Default event handler.
   * Passes the event to the private function.
   */
  handleEvent(event) {
    this._handleEvent(event);
  }

  /**
   * Fires after the component has been mounted.
   * Loads the necessary data and starts the questionaire.
   */
  componentDidMount() {
      //TODO – should be managable with a ref-callback.
      //@see https://facebook.github.io/react/docs/more-about-refs.html
      //ref={ fast.bind(this._handleRakete,this) }
      //does not work. :(
      this._handleRakete(this.refs.rakete);

      this.data.load().then(() => {
          this._animateRaketeInitial(() => {
          });

          let question = this.data.questions.getForIndex(this.step);
          
          this._addQuestion(question);
          this._calculateTotalQuestionCount();
      });

      window.scrollTo(0, 0);
  }

  /**
   * Fired immerdiately before the component is mounted.
   * Sets a class to the body for specific styling.
   * Adds event listeners to the window.
   */
  componentWillMount() {
    this.router = this.context.router;
    this.params = this.router.getCurrentParams();

    addClass(document.body, 'isQuestions');

    window.addEventListener('resize', this, false);
    window.addEventListener('orientationchange', this, false);
    window.addEventListener('keydown', this, false);
  }

  /**
   * Called immediately before the component is removed.
   * Removes classes and event listeners.
   */
  componentWillUnmount() {
    removeClass(document.body, 'isQuestions');
    window.removeEventListener('resize', this);
    window.removeEventListener('orientationchange', this);
    window.removeEventListener('keydown', this);
  }

  /**
   * The default render method.
   */
  render() {
      return (
        <div className="questions">
          <div className="tunnel" ref="tunnel">

          </div>
          <div className="flightpath" ref="flightpath">
            <div className="flightpath__rakete" ref="rakete"></div>
          </div>
          <header className="header" ref="header">
            <div className="header__content">

            </div>
          </header>
          <div className="main__questions">
              <QuestionList questions={this.state.test} onChoose={fast.bind(this._handleChoice,this)} count={this.state.totalQuestionCount} />
          </div>
          <div className="clouds" ref="clouds">
            <span className="clouds__left" ref="doorLeft"></span>
            <span className="clouds__right" ref="doorRight"></span>
            <div className="clouds__machine">
              <span className="clouds__machine__headline">Dein Ergebnis wird nun berechnet.</span>
            </div>
          </div>
        </div>
      );
  }
};
Questions.propTypes = {
  "test": React.PropTypes.array
};

Questions.defaultProps = {
  "test": []
};

Questions.contextTypes = {
  router: React.PropTypes.func
};

export default Questions;
