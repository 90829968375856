/**
 * @fileOverview PhaseTwo class.
 * @module frontend/models/phaseTwo
 * @author Joris Karl Dzaack <dzaack@laborb.de>
 * @copyright labor b designbüro, 2015
 */
'use strict';

import fast from 'fast.js';
import uuid from '../utils/uuid.js';

const DEBUG = false;

/**
 * PhaseTwo class
 * @classdesc Generates all questions for the second phase
 * @class PhaseTwo
 * @public
 */
export default class PhaseTwo {
  /**
   * Constructor of the PhaseTwo class
   * @constructs PhaseTwo
   */
  constructor() {
  	this._choices = {};
  	this._phaseTwoCount = [];
  	this._intermediate = {};
  	this._phaseTwoQuestions = [];
  }

  /**
   * Converts an array of choices to a object
   * @memberOf PhaseTwo
   * @private
   * @param  {array} choices the choices array
   * @return {object}         the new choices object
   */
  _choiceArrayToObject(choices) {
	return fast.reduce(choices, function(previousValue, currentValue) {
		previousValue[currentValue.id] = currentValue;
		return previousValue;
	},{});
  }

  /**
   * Calculates the phase to values
   * @memberOf PhaseTwo
   * @private
   * @param  {string} id The choices id
   * @return {object}    Object containing id, value and matrix of the question
   */
  _calculatePhaseTwoValue(id) {
    var current = this._choices[id].values;

    var a = 0,
    b = 0,
    phaseTwoValue = 0,
    returnObject = {}; 

    if(current.hasOwnProperty(this._intermediate[0][0]) && current.hasOwnProperty(this._intermediate[0][1])) {
      a = parseInt(current[this._intermediate[0][0]]);
      b = parseInt(current[this._intermediate[0][1]]);
    }
    
    else if(current.hasOwnProperty(this._intermediate[1][0]) && current.hasOwnProperty(this._intermediate[1][1])) {
      a = parseInt(current[this._intermediate[1][0]]);
      b = parseInt(current[this._intermediate[1][1]]);
    }

    phaseTwoValue = a - b;
    returnObject.id = id;
    returnObject.phaseTwoValue = phaseTwoValue;
    returnObject.matrix = this._choices[id].matrix;

    if(phaseTwoValue !== 0 && DEBUG) {
    	console.log('Phase-Two-Value: %s-%s = %s', a, b, phaseTwoValue);
    	console.log('(%s): %s (%s): %s (%o)', this._choices[id].matrix, this._choices[id].text, this._choices[id].id, phaseTwoValue, this._choices[id]);
  	}

    return returnObject;
  }

  /**
   * Filters and returns the possible choices for the second phase and calculates their values.
   * Filters all choices which have no value grater than 0.
   * @memberOf PhaseTwo
   * @private
   * @see _calculatePhaseTwoValue
   * @return {array} Array of objects containing the id, the value and the matrix of each question.
   */
  _calculatePhaseTwoQuestionIds() {
  	let filteredChoices = fast.filter(Object.keys(this._choices), (id) => {
      return this._choices[id].phaseOne === false;
    });

    let phaseTwoQuestionIds = fast.map(filteredChoices, this._calculatePhaseTwoValue, this);

    phaseTwoQuestionIds = fast.filter(phaseTwoQuestionIds, (value) => {
      return value.phaseTwoValue === 0 ? false : true;
    });

    if(DEBUG) console.log('Phase Two Question Ids: %o', phaseTwoQuestionIds);

    return phaseTwoQuestionIds;
  }

  /**
   * Method used for generating pairs of questions
   * @memberOf PhaseTwo
   * @see  _calculatePhaseTwoQuestions
   * @param  {array} data  Array containing the questions objects
   * @param  {number} id   Number of current matrix; used for debugging
   * @return {array}       multidimensional array containing the question tupels
   */
  _generateQuestionTupel(data,id) {
    let questions = [],
    normal = [],
    reverse = [];

    normal = data.sort(function(a,b) {
        if(a.phaseTwoValue < b.phaseTwoValue) return -1;
        if(a.phaseTwoValue > b.phaseTwoValue) return 1;
        return 0;
    }).reverse();

    reverse = fast.clone(normal).reverse();

    normal = fast.filter(normal, function(value) {
        return value.phaseTwoValue >= 0;
    });

    reverse = fast.filter(reverse, function(value) {
        return value.phaseTwoValue <= 0;
    });

    if(DEBUG) console.log('Matrix(%s): Normal: %o\nReverse: %o', id, normal, reverse);

    for(var n = 0; n < (normal.length >= reverse.length ? normal.length : reverse.length); n++) {
        if(normal[n] && reverse[n] && normal[n].hasOwnProperty('id') && reverse[n].hasOwnProperty('id')) {
            var a = normal[n].id,
            b = reverse[n].id;

            questions.push([a, b]);
        }
    }

    return questions;
  }

  /**
   * Calculates the questions for the second phase
   * @memberOf PhaseTwo
   * @see  _generateQuestionsTupel
   * @see  _calculatePhaseTwoQuestionIds
   * @return {undefined}
   */
  _calculatePhaseTwoQuestions() {
  	let phaseTwoQuestionIds = this._calculatePhaseTwoQuestionIds();

  	let filterMatrix = function(matrixId, question) {
  		return question.matrix === matrixId;
  	};

  	let matrices = [];
  	matrices.push(fast.filter(phaseTwoQuestionIds, fast.partial(filterMatrix, 0)));
  	matrices.push(fast.filter(phaseTwoQuestionIds, fast.partial(filterMatrix, 1)));

    let phaseTwoQuestionObjects = fast.map(matrices, (matrix, id) => {
      return fast.map(this._generateQuestionTupel(matrix,id), (value) => {
        return {
        	'id': uuid(),
            'q1': this._choices[value[0]],
            'q2': this._choices[value[1]]
        };
      }).splice(0,this._phaseTwoCount[id]);
    });

    phaseTwoQuestionObjects = fast.reduce(phaseTwoQuestionObjects, function(a, b) {
      return fast.concat(a,b);
    });

    if(DEBUG) console.log('Phase Two Question Objects: %o', phaseTwoQuestionObjects);

    this._phaseTwoQuestions = phaseTwoQuestionObjects;
  }

  /**
   * Returns a question for a specific index
   * @memberOf PhaseTwo
   * @public
   * @param  {number} index index in array
   * @return {object}       question object
   */
  getForIndex(index) {
  	return this._phaseTwoQuestions[index];
  }

  /**
   * Returns all questions for the second phase
   * @memberOf PhaseTwo
   * @type {object}
   * @return {object} the object containing the questions
   */
  get questions() {
  	return this._phaseTwoQuestions;
  }

  /**
   * Fills the internal values of the instance
   * @memberOf PhaseTwo
   * @public
   * @param  {object} data         the data object containing all the raw data out of the database
   * @param  {object} intermediate object containing the intermediate values
   * @return {undefined}           
   */
  calculate(data, intermediate) {
  	this._choices = this._choiceArrayToObject([...data.choices]);
  	this._phaseTwoCount = data.settings.phaseTwoCount;
  	this._intermediate = intermediate.choices;

  	if(DEBUG) console.log(this._choices);

  	this._calculatePhaseTwoQuestions();
  }
}
