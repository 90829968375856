/**
 * @fileOverview Type model.
 * @module frontend/models/type
 * @author Joris Karl Dzaack <dzaack@laborb.de>
 * @copyright labor b designbüro, 2015
 */
'use strict';

/**
 * Type model
 * Contains the model for each type
 * @class Type
 * @public
 */
export default class Type {
    /**
     * Constructor for the Type model
     * @constructs Type
     * @param  {object} data object containing the data necessary for the type
     */
    constructor(data) {
        this.id = data._id;
        this.matrix = data.matrix;
        this.name = data.name;
        this.values = data.values;
        this.weight = data.weight;
    }
}
