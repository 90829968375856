/**
 * @file
 * Holds the BerufsFeld component, which writes the result's name to the page.
 * @fileOverview
 * Writes the result's name to the page
 * @module react/BerufsFeld
 * @author Joris Karl Dzaack <dzaack@laborb.de>
 * @copyright labor b designbüro, 2015
 */
'use strict';

import React from 'react/addons';

import Types from '../frontend/models/Types.js';

/**
 * Berufsfeld component
 * @classdesc The component for the "Berufsfeld". It writes the result's name to the page.
 * @requires module:frontend/models/types
 * @class BerufsFeld
 * @public
 */
class BerufsFeld extends React.Component {
  /**
   * @constructs Berufsfeld
   */
  constructor(props) {
      super(props);

      this.state = {
          'type': {}
      };

      this._init();
  }

  /**
   * Initializes the class
   */
  _init() {
    this._loadAndSetTypes();
  }

  /**
   * Loads types and sets the specific type to the state
   */
  _loadAndSetTypes() {
    let types = new Types();
    types.load().then(() => {
        this.setState({
            'type': types.getTypeForId(this.props.typeId)
        });
    });
  }

  /**
   * Default render method
   */
  render() {
    return (
        <div className="berufsfeld">
            <h4 className="berufsfeld__title">Dieses Berufsfeld passt zu dir</h4>
            <h3 className="berufsfeld__name">{this.state.type.name}</h3>
        </div>
    );
  }
}

BerufsFeld.propTypes = {
    typeId: React.PropTypes.string
};

export default BerufsFeld;
