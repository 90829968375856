/**
 * @fileOverview Removes a image map after a specific breakpoint
 * @module utils/ImageMap
 * @author Joris Karl Dzaack <dzaack@laborb.de>
 * @copyright labor b designbüro, 2015
 */

'use strict';

import {forEach} from 'fast.js';

/**
 * ImageMap class
 * @classdesc Removes the image map after a specific breakpoint
 * @class ImageMap
 * @public
 */
export default class ImageMap {
  constructor (query) {
    this._query = query;
    this._resizeTimeout = null;
    window.addEventListener('resize', this, false);
  }

  fire() {
    this._toggleMaps();
  }

  _hasMatchMedia() {
    return (window.matchMedia) ? true : false;
  }

  _removeMap(images) {
    forEach(images, (img) => {
      if(img.hasAttribute('usemap')) {
        let usemap = img.getAttribute('usemap');
        img.setAttribute('data-usemap', usemap);
        img.removeAttribute('usemap');
      }
    });
  }

  _addMap(images) {
    forEach(images, (img) => {
      if(img.hasAttribute('data-usemap')) {
        let usemap = img.getAttribute('data-usemap');
        img.setAttribute('usemap', usemap);
        img.removeAttribute('data-usemap');
      }
    });
  }

  _toggleMaps() {
    const imagesWithMap = [].slice.call(document.getElementsByTagName('img')).filter(function(img) {
      return img.hasAttribute('usemap') || img.hasAttribute('data-usemap');
    });

    if(this._hasMatchMedia()) {
      if(window.matchMedia(this._query).matches) {
        this._removeMap(imagesWithMap);
      }
      else {
        this._addMap(imagesWithMap);
      }
    }
  }

  _resizeHandler() {
    if(!this._resizeTimeout) {
      this._resizeTimeout = setTimeout(() => {
        this._resizeTimeout = null;
        this._toggleMaps();
      }, 66);
    }
  }

  _handleEvent(event) {
    switch(event.type) {
      case 'resize':
        this._resizeHandler.call(this);
      break;
    }
  }

  handleEvent(event) {
    this._handleEvent.call(this,event);
  }
}
