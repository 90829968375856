/**
 * @fileOverview Choices Modell.
 * @module frontend/models/choices
 * @author Joris Karl Dzaack <dzaack@laborb.de>
 * @copyright labor b designbüro, 2015
 */

'use strict';

import DataHandler from './DataHandler.js';
import Choice from './Choice.js';

/**
 * Choices class
 * @classdesc Gets all choices from an API call
 * @extends DataHandler
 * @see module:frontend/models/dataHandler
 * @class Choices
 * @public
 */
export default class Choices extends DataHandler {
    /**
     * Constructor of the Choices class
     * @memberOf Choices
     * @constructs Choices
     */
    constructor() {
        super();
        this.path = '/api/choices';
    }

    /**
     * Loads and returns the choices array
     * @memberOf Choices
     * @return {promise} Choices array
     * @override
     */
    load() {
        return super.load().then(() => {
            this.data = this.data.map((choice) => {
                return new Choice(choice);
            });
        });
    }
}
