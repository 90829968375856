/**
 * @fileOverview backgroundPosition module.
 * @module utils/backgroundPosition
 * @author Joris Karl Dzaack <dzaack@laborb.de>
 * @copyright labor b designbüro, 2015
 */

/**
 * Function for getting the current background position
 * @param  {HTMLElement} element DOM Element
 * @return {object}         object containing the background position in different units
 */
export default function backgroundPosition (element) {
  'use strict';

  let computedBackgroundPosition = window.getComputedStyle(element,null).backgroundPosition.trim().split(/\s+/);
  
  return {
    'left' : computedBackgroundPosition[0],
    'top' : computedBackgroundPosition[1],
    'numbers' : {
      'left' : parseFloat(computedBackgroundPosition[0]),
      'top' : parseFloat(computedBackgroundPosition[1])
    },
    'units' : {
      'left' : computedBackgroundPosition[0].replace(/\d+/,''),
      'top' : computedBackgroundPosition[1].replace(/\d+/,'')
    }
  };
}
