/**
 * @fileOverview stripHtml module.
 * @module utils/stripHtml
 * @author Joris Karl Dzaack <dzaack@laborb.de>
 * @copyright labor b designbüro, 2015
 */
'use strict';

/**
 * Strips HTML from a string
 * @param  {String} html The HTML string to process
 * @return {String}      The plain text
 */
export default function stripHtml (html) {
  var tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
}
