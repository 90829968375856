/**
 * @fileOverview Question model.
 * @module frontend/models/question
 * @author Joris Karl Dzaack <dzaack@laborb.de>
 * @copyright labor b designbüro, 2015
 */
'use strict';

/**
 * Question model
 * @classdesc Contains the model for each question
 * @class Question
 * @public
 */
export default class Question {
    /**
     * Constructor for the Question model
     * @constructs Question
     * @param  {string} data object containing the data necessary for the question
     */
    constructor(data) {
        this.id = data._id;
        this.matrix = data.matrix;
        this.q1 = data.q1;
        this.q2 = data.q2;
    }

    /**
     * Populates the quetions data
     * @memberOf Question
     * @param  {array} choices Array holding all choices from the database
     * @return {undefined}
     */
    getData(choices) {
        for(let choice of choices) {
            if(this.q1 === choice.id) {
                this.q1 = choice;
            }

            if(this.q2 === choice.id) {
                this.q2 = choice;
            }
        }
    }
}
