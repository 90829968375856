/**
 * @file
 * Holds the Question component.
 * @fileOverview
 * Holds the Question component.
 * @module react/Question
 * @author Joris Karl Dzaack <dzaack@laborb.de>
 * @copyright labor b designbüro, 2015
 */
'use strict';

import React from 'react/addons';
import fast from 'fast.js';
import classNames from 'classnames';

import addClass from 'amp-add-class';
import removeClass from 'amp-remove-class';

import smoothScroll from '../frontend/utils/smoothScroll.js';
import backgroundPosition from '../frontend/utils/backgroundPosition.js';

import Choice from './Choice.jsx';

/**
 * Question component
 * @classdesc The component for the "Question".
 * @requires module:react/Choice
 * @requires module:frontend/utils/smoothScroll
 * @requires module:frontend/utils/backgroundPosition
 * @requires module:config/site
 * @class Question
 * @public
 */
class Question extends React.Component {
  /**
   * Contructs Question
   * Sets the default classes and state.
   * Adds new timelines for animation.
   * @constructs Question
   */
  constructor(props) {
    super(props);

    // Update to Set
    // Keep adding and removing classes DRY
    this.classes = ['choices'];


    this.state = {
        "classes": classNames(this.classes),
        "answered": false
    };

    this.timelines = {
      next: new TimelineMax({delay:0})    
    };

    this.rakte = null;
  }

  /**
   * Handles the choice.
   * Handles the choice bubbled from the Choice component.
   * @private
   */
  _handleChoice(choice) {
      if(!this.state.answered) {
          this.classes.push('answered');

          this.props.onChoose(choice);

          this.setState({
              "classes": classNames(this.classes),
              "answered": true
          });
      }
  }

  /**
   * Animates the rocket.
   * Animates the rocket according to its state.
   * @private
   */
  _handleScroll(time) {
    if(!this.rakete) {
      this.rakete = document.getElementsByClassName('flightpath__rakete')[0]; 
    }

    switch(time) {
      case 'start':
        this._handleScrollStart(this.rakete);
      break;
      case 'end':
        this._handleScrollEnd(this.rakete);
      break;
      default:
      //nothing to do
    }
  }

  /**
   * Adds a class to the rocket.
   * Adds the drilling animation.
   * @private
   * @param  {HTMLElement} rakete The DOM node for the rocket
   */
  _handleScrollStart(rakete) {
    addClass(rakete, 'drilling');
  }

  /**
   * Removes a class from the rocket.
   * Removes the drilling animation.
   * @private
   * @param  {HTMLElement} rakete The DOM node for the rocket
   */
  _handleScrollEnd(rakete) {
    removeClass(rakete, 'drilling');
  }

  /**
   * Fires after the component has been mounted.
   * Animates the scrolling.
   * Animates the drilling on the x axis.
   */
  componentDidMount() {
    const tunnelNew = document.getElementsByClassName('tunnel')[0];
    const flightPath = document.getElementsByClassName('flightpath')[0];
    const mainQuestions = document.getElementsByClassName('main__questions')[0];

    smoothScroll.animateScroll(null, `#question${this.props.id}`, {
      speed: 1800,
      updateURL: false,
      easing: 'easeInOutSine',
      callbackBefore: this._handleScroll.bind(this, 'start'),
      callbackAfter: this._handleScroll.bind(this, 'end')
    });

    this.timelines.next
      .to([tunnelNew, flightPath], 2, {ease: Sine.easeInOut, css:{height: `${mainQuestions.offsetHeight}px`}},'next')
      .add('next');
  }

  /**
   * Default render method.
   */
  render() {
      return (
          <div className={this.state.classes}>
              <span className="choices__step"><strong className="choices__step__headline">{this.props.text}</strong><br />
              <strong className="choices__step__counter">Schritt {this.props.id+1} / {this.props.count}</strong></span>
              <Choice answered={this.state.answered} choice={this.props.question.q1} onChoose={fast.bind(this._handleChoice,this)} keyHandler={65} count={this.props.count} id={this.props.id} />
              <Choice answered={this.state.answered} choice={this.props.question.q2} onChoose={fast.bind(this._handleChoice,this)} keyHandler={66} count={this.props.count} id={this.props.id} />
          </div>
      );
  }
}
Question.propTypes = {
    text: React.PropTypes.string,
    question: React.PropTypes.object,
    onChoose: React.PropTypes.func.isRequired,
    id: React.PropTypes.number,
    count: React.PropTypes.number
};
Question.defaultProps = {
  text: '',
  question: {}
};

export default Question;
